/**
 * TYT Platform API
 * This is the TYT Platform API. It is used by TYT's apps to interact 
 * with the underlying platform. 
 *
 * NOTE: This file is auto generated. 
 * Do not edit this file manually.
 */


import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import * as models from '../model/models';
import { Requester } from '../requester';
import { PagedArray } from '../paged-array';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class UsersApi {
    constructor(
        protected requester: Requester
    ) {
    }

    /**
     * Check if user should login by email
     * @param body 
     */
    public emailConflict(body?: models.ApiPostUsersEmailConflict, __unused?: any): Observable<{}> {
        const path = '/users/email/conflict';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<any>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Get the honor roll list
     */
    public getHonorRoll(__unused?: any): Observable<PagedArray<models.ApiHonorRoll>> {
        const path = '/users/honor-roll';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiHonorRoll>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Determine if a person is eligible to be a gift recipient
     * @param email 
     */
    public giftEligibleByEmail(email?: string, __unused?: any): Observable<models.ApiGiftEligibility> {
        const path = '/users/giftEligible';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 
        if (email !== undefined) {
            queryParameters['email'] = <any>email;
        }


        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiGiftEligibility>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Sign up email address with newletters for email
     * @param body 
     */
    public signupNewsletter(body?: models.ApiPostUsersSignupNewsletter, __unused?: any): Observable<{}> {
        const path = '/users/signup/newsletter';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<any>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Sign up for a TYT account
     * @param body 
     */
    public signup(body?: models.ApiPostUsersSignup, __unused?: any): Observable<models.ApiUserAuthToken> {
        const path = '/users/signup';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiUserAuthToken>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Confirm email for a user
     * @param body 
     */
    public confirmEmail(body?: models.ApiPostUsersConfirmemail, __unused?: any): Observable<models.ApiUserAuthToken> {
        const path = '/users/confirmEmail';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiUserAuthToken>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Resend confirmation email Sends no indication of success. App should assume it was successful if there was indeed an account with the given email address.
     * @param body 
     */
    public sendConfirmationEmail(body?: models.ApiPostUsersSendconfirmationemail, __unused?: any): Observable<{}> {
        const path = '/users/sendConfirmationEmail';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<any>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Fulfill an account unlock request
     * @param body 
     */
    public unlockAccount(body?: models.ApiPostUsersUnlockaccount, __unused?: any): Observable<{}> {
        const path = '/users/unlockAccount';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<any>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Send an email to unlock account Returns no indication of success. App should assume that email was sent provided an account existed with that address.
     * @param body 
     */
    public requestAccountUnlock(body?: models.ApiPostUsersRequestunlock, __unused?: any): Observable<{}> {
        const path = '/users/requestUnlock';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<any>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Fulfill a password reset request
     * @param body 
     */
    public resetPassword(body?: models.ApiPostUsersResetpassword, __unused?: any): Observable<models.ApiUserAuthToken> {
        const path = '/users/resetPassword';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiUserAuthToken>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Assume a user by token Must pass Platform session cookie. Platform Assume User feature must have been used within that session prior to this API call.
     */
    public assume(__unused?: any): Observable<models.ApiUserAuthToken> {
        const path = '/users/assume';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiUserAuthToken>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Send a password reset request Returns no indication of success. App should assume that reset email was sent if an account existed, otherwise it was not sent.
     * @param body 
     */
    public requestPasswordReset(body?: models.ApiPostUsersRequestpasswordreset, __unused?: any): Observable<{}> {
        const path = '/users/requestPasswordReset';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<any>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Authenticate using twitter, facebook, or google oauth credentials Requires at least a token. Twitter also requires a secret (shh).
     * @param body 
     */
    public userOmniauthAuth(body?: models.ApiPostUsersOmniauth, __unused?: any): Observable<models.ApiUserAuthToken> {
        const path = '/users/omniauth';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiUserAuthToken>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Authenticate as a user to obtain an auth token
     * @param body 
     */
    public authenticate(body?: models.ApiPostUsersAuth, __unused?: any): Observable<models.ApiUserAuthToken> {
        const path = '/users/auth';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiUserAuthToken>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * 
     */
    public postUsersSso(__unused?: any): Observable<{}> {
        const path = '/users/sso';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<any>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Register (or update) a device with the current user&#x2F;guest
     * @param body 
     */
    public registerDevice(body?: models.ApiPostUsersCurrentDevices, __unused?: any): Observable<models.ApiDevice> {
        const path = '/users/current/devices';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiDevice>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Kick off generating a new data download archive for the authenticated user
     */
    public startDataDownload(__unused?: any): Observable<{}> {
        const path = '/users/current/data-download/start';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<any>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Get the payment source
     */
    public getPaymentSource(__unused?: any): Observable<models.ApiPaymentSource> {
        const path = '/users/current/payment';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiPaymentSource>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Delete a Web Push subscription for the current user
     * @param body 
     */
    public deleteWebPushSubscription(body?: models.ApiPostUsersCurrentWebpushSubscriptionsDelete, __unused?: any): Observable<{}> {
        const path = '/users/current/webpush/subscriptions/delete';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<any>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Register a Web Push subscription for the current user
     * @param body 
     */
    public addWebPushSubscription(body?: models.ApiPostUsersCurrentWebpushSubscriptions, __unused?: any): Observable<{}> {
        const path = '/users/current/webpush/subscriptions';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<any>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Perform a permissions check for the current user
     * @param body 
     */
    public checkPermission(body?: models.ApiPostUsersCurrentPermissionsCheck, __unused?: any): Observable<models.ApiPermissionCheckResult> {
        const path = '/users/current/permissions/check';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiPermissionCheckResult>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Get account transactions for the current user
     * @param page 
     */
    public getAccountTransactions(page?: number, __unused?: any): Observable<PagedArray<models.ApiAccountTransaction>> {
        const path = '/users/current/transactions';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 
        if (page !== undefined) {
            queryParameters['page'] = <any>page;
        }


        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiAccountTransaction>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Delete the current user
     * @param body 
     */
    public deleteCurrentUser(body?: models.ApiPostUsersCurrentDelete, __unused?: any): Observable<models.ApiDeletionConfirmation> {
        const path = '/users/current/delete';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiDeletionConfirmation>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Get the current user
     */
    public getCurrentUser(__unused?: any): Observable<models.ApiUser> {
        const path = '/users/current';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiUser>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Update user
     * @param body 
     */
    public updateUser(body?: models.ApiPostUsersCurrent, __unused?: any): Observable<models.ApiUser> {
        const path = '/users/current';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiUser>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Create a PaymentIntent for a contribution or subscription purchase as necessary for the given offer set
     * @param offer_set_uuid 
     * @param offer_uuid 
     */
    public getStripePaymentIntent(offer_set_uuid?: string, offer_uuid?: string, __unused?: any): Observable<models.ApiStripePaymentIntent> {
        const path = '/users/current/stripe/payment-intent/offer-sets/{offer_set_uuid}/{offer_uuid}'
                    .replace('{' + 'offer_set_uuid' + '}', String(offer_set_uuid))
                    .replace('{' + 'offer_uuid' + '}', String(offer_uuid));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiStripePaymentIntent>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Get a RetentionOffer entity based on the offer code sent by the user
     * @param code 
     */
    public getRetentionOffer(code?: string, __unused?: any): Observable<models.ApiRetentionOfferSet> {
        const path = '/users/membership/retention/offers/{code}'
                    .replace('{' + 'code' + '}', String(code));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiRetentionOfferSet>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Reactivate a previously cancelled TYT membership
     */
    public reactivateMembership(__unused?: any): Observable<models.ApiMembership> {
        const path = '/users/current/membership/reactivate';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiMembership>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Cancel an existing TYT membership
     * @param body 
     */
    public cancelMembership(body?: models.ApiPostUsersCurrentMembershipCancel, __unused?: any): Observable<models.ApiMembership> {
        const path = '/users/current/membership/cancel';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiMembership>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Preview a product change on an existing TYT membership
     * @param body 
     */
    public previewMembershipChange(body?: models.ApiPostUsersCurrentMembershipModifyPreview, __unused?: any): Observable<models.ApiMembershipChangePreview> {
        const path = '/users/current/membership/modify/preview';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiMembershipChangePreview>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Change the membership product on an existing TYT membership
     * @param body 
     */
    public modifyMembership(body?: models.ApiPostUsersCurrentMembershipModify, __unused?: any): Observable<models.ApiMembership> {
        const path = '/users/current/membership/modify';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiMembership>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Refresh authentication token
     * @param body 
     */
    public refreshAuthentication(body?: models.ApiPostUsersAuthRefresh, __unused?: any): Observable<models.ApiUserAuthToken> {
        const path = '/users/auth/refresh';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiUserAuthToken>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Update payment source on an existing membership
     * @param body 
     */
    public changePaymentSource(body?: models.ApiPostUsersCurrentMembershipChangepayment, __unused?: any): Observable<models.ApiMembership> {
        const path = '/users/current/membership/changePayment';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiMembership>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Sign up for a premium TYT membership for an existing user account
     * @param body 
     */
    public createMembership(body?: models.ApiPostUsersCurrentMembership, __unused?: any): Observable<models.ApiMembership> {
        const path = '/users/current/membership';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiMembership>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Update your profile
     * @param body 
     */
    public updateProfile(body?: models.ApiPostUsersCurrentProfile, __unused?: any): Observable<models.ApiUserProfile> {
        const path = '/users/current/profile';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiUserProfile>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Set your cover photo
     * @param body 
     */
    public updateCoverPhoto(body?: models.ApiPostUsersCurrentCoverphoto, __unused?: any): Observable<models.ApiUserProfile> {
        const path = '/users/current/coverPhoto';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiUserProfile>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Set your avatar
     * @param body 
     */
    public updateAvatar(body?: models.ApiPostUsersCurrentAvatar, __unused?: any): Observable<models.ApiUserProfile> {
        const path = '/users/current/avatar';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiUserProfile>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Add or update oauth credentials for the current user
     * @param body 
     */
    public upsertUserOmniauth(body?: models.ApiPostUsersCurrentOmniauth, __unused?: any): Observable<models.ApiUserOmniauth> {
        const path = '/users/current/omniauth';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiUserOmniauth>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Initiate a user account merge operation
     * @param body 
     */
    public mergeAccounts(body?: models.ApiPostUsersMerge, __unused?: any): Observable<models.ApiUserAuthToken> {
        const path = '/users/merge';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiUserAuthToken>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Reset your password
     * @param body 
     */
    public resetMyPassword(body?: models.ApiPostUsersPasswordReset, __unused?: any): Observable<{}> {
        const path = '/users/password/reset';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<any>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Disconnect a connected account
     * @param id 
     */
    public disconnectExternalAccount(id?: string, __unused?: any): Observable<{}> {
        const path = '/users/connected-accounts/{id}/disconnect'
                    .replace('{' + 'id' + '}', String(id));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<any>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Get a Public Profile
     * @param userid 
     */
    public getPublicProfile(userid?: string, __unused?: any): Observable<models.ApiPublicProfile> {
        const path = '/users/{userid}'
                    .replace('{' + 'userid' + '}', String(userid));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiPublicProfile>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Report a user profile
     * @param userid 
     * @param body 
     */
    public reportProfile(userid?: string, body?: models.ApiPostUsersUseridReport, __unused?: any): Observable<{}> {
        const path = '/users/{userid}/report'
                    .replace('{' + 'userid' + '}', String(userid));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<any>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Retrieve an authentication token for testing This is only available in development environments. Returns 400 Bad Request when used in an unsupported environment.
     */
    public postUsersAuthTest(__unused?: any): Observable<{}> {
        const path = '/users/auth/test';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<any>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Retrieve a specific user&#39;s auth token for testing (development only) This is only available in development environments. Returns 400 Bad Request when used in an unsupported environment.
     * @param id 
     */
    public postUsersAuthTestId(id?: number, __unused?: any): Observable<{}> {
        const path = '/users/auth/test/{id}'
                    .replace('{' + 'id' + '}', String(id));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<any>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Get a user snippet
     * @param username 
     */
    public getUserSnippet(username?: string, __unused?: any): Observable<models.ApiUserSnippet> {
        const path = '/users/{username}/snippet'
                    .replace('{' + 'username' + '}', String(username));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiUserSnippet>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Resolve a spot conversation ID to the appropriate URL on TYT.com
     * @param identifier 
     */
    public resolveSpot(identifier?: number, __unused?: any): Observable<{}> {
        const path = '/spot/{identifier}'
                    .replace('{' + 'identifier' + '}', String(identifier));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<any>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Translate Spot.im user ID to TYT identity
     * @param userid 
     */
    public translateSpotUserID(userid?: string, __unused?: any): Observable<models.ApiSpotUserLookupResult> {
        const path = '/spot/users/{userid}'
                    .replace('{' + 'userid' + '}', String(userid));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiSpotUserLookupResult>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Get the user&#39;s partially watched content
     */
    public getOpenSessions(__unused?: any): Observable<PagedArray<models.ApiPlaybackSession>> {
        const path = '/content/playback/open_sessions';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiPlaybackSession>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

}
